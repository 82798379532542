.animated-image-grid {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-image-grid.in-view {
  opacity: 1;
  transform: translateY(0);
}

.animated-image-grid .image-container {
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScale 0.5s ease-out forwards;
  animation-play-state: paused;
}

.animated-image-grid.in-view .image-container {
  animation-play-state: running;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animated-image-grid .image-container:hover img {
  transform: scale(1.1);
}

.animated-image-grid .image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.animated-image-grid .image-container:hover::after {
  opacity: 1;
}
