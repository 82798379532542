.fountain-section {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a2e);
  overflow: hidden;
  padding: 6rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 2;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7),
    0 0 20px rgba(255, 255, 255, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.title-word {
  animation: color-animation 4s linear infinite;
  padding: 0 10px;
}

.title-word:nth-child(1) {
  animation-delay: 0s;
}
.title-word:nth-child(2) {
  animation-delay: 0.25s;
}
.title-word:nth-child(3) {
  animation-delay: 0.5s;
}
.title-word:nth-child(4) {
  animation-delay: 0.75s;
}

/* @keyframes color-animation {
  0% {
    color: #05d9e8;
  }
  32% {
    color: #005678;
  }
  66% {
    color: #ff7d00;
  }
  100% {
    color: #05d9e8;
  }
} */

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
  justify-content: center;
}

.fountain-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.fountain-image:hover {
  transform: scale(1.05);
}

.fountain-animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  display: flex;
  justify-content: space-around;
}

.water-column {
  display: flex;
  justify-content: center;
  width: 5%;
}

.water {
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 0;
  background: #4fc3f7;
  animation: fountain 3s infinite ease-out;
}

.water:nth-child(1) {
  animation-delay: 0s;
}
.water:nth-child(2) {
  animation-delay: 0.2s;
  left: 2px;
}
.water:nth-child(3) {
  animation-delay: 0.4s;
  left: -2px;
}

@keyframes fountain {
  0% {
    height: 0;
    opacity: 1;
  }
  50% {
    height: 80%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2.5rem;
  }

  .image-grid {
    grid-template-columns: 1fr;
  }

  .fountain-image {
    max-width: 300px;
  }

  .water-column {
    width: 10%;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2rem;
  }

  .fountain-image {
    max-width: 250px;
  }

  .water-column {
    width: 20%;
  }
}
