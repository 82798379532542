.about-us {
  min-height: 100vh;
  background: linear-gradient(135deg, #0c4a6e, #0369a1, #0284c7);
  color: white;
  padding: 5rem 1rem;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.about-us.visible {
  opacity: 1;
  transform: translateY(0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 3rem;
  animation: fadeInDown 1s ease-out;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.info-box {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: fadeInLeft 1s ease-out;
}

.info-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: rotate 10s linear infinite;
}

.info-box h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  animation: fadeInRight 1s ease-out;
}

.buttons button {
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #0284c7;
  color: white;
}

.buttons button:hover {
  background-color: #0369a1;
  transform: translateY(-2px);
}

.buttons button.active {
  background-color: white;
  color: #0284c7;
}

.cta {
  text-align: center;
  margin-top: 4rem;
  animation: fadeInUp 1s ease-out;
}

.cta h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.get-started {
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  font-size: 1.15rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
  color: #0284c7;
  position: relative;
  margin-top: 8rem;
}

.get-started:hover {
  background-color: #f0f9ff;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
