.animate-color-change {
  animation: color-change 4s linear infinite;
}

.animation-delay-330 {
  animation-delay: 0.33s;
}

.animation-delay-660 {
  animation-delay: 0.66s;
}

.fountain-item {
  position: relative;
  perspective: 1000px;
}

.fountain-item:hover .fountain-overlay {
  transform: translateY(0);
}

.fountain-item.visible {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.fountain-item {
  animation: float 6s ease-in-out infinite;
}

.fountain-item:nth-child(odd) {
  animation-delay: -3s;
}

@keyframes shine {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}

.fountain-item::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shine 3s infinite;
}

.fountain-overlay {
  backdrop-filter: blur(4px);
}

@media (prefers-reduced-motion: reduce) {
  .fountain-item,
  .fountain-item::before {
    animation: none;
  }
}
