.products-teaser {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #011f4b;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.products-teaser.visible {
  opacity: 1;
}

.water-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #011f4b, #03396c);
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  border-radius: 40%;
  animation: wave 15s infinite linear;
  opacity: 0.2;
}

.wave:nth-child(1) {
  background: radial-gradient(circle at center, #6497b1 0%, transparent 70%);
  animation-duration: 20s;
}

.wave:nth-child(2) {
  background: radial-gradient(circle at center, #005b96 0%, transparent 70%);
  animation-duration: 25s;
  animation-delay: -5s;
}

.wave:nth-child(3) {
  background: radial-gradient(circle at center, #03396c 0%, transparent 70%);
  animation-duration: 30s;
  animation-delay: -10s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bubble {
  position: absolute;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 70%
  );
  border-radius: 50%;
  animation: bubble 10s infinite ease-in-out;
}

@keyframes bubble {
  0%,
  100% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  50% {
    transform: translateY(-100vh) scale(1.5);
    opacity: 0.7;
  }
}

.content-wrapper {
  position: relative;
  z-index: 1;
  text-align: center;
}

.heading {
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 4rem;
  perspective: 1000px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.word {
  display: inline-block;
  margin: 0 0.25rem;
  animation: flipIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) backwards;
}

@keyframes flipIn {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.products-button {
  position: relative;
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  background-color: #005b96;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  text-decoration: none;
}

.products-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 91, 150, 0.6);
}

.button-text {
  position: relative;
  z-index: 2;
}

.button-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 0;
}

.products-button:hover .particle {
  animation: particleEffect 0.6s ease-out infinite;
}

@keyframes particleEffect {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(
      calc(random(100) * 1% - 50%),
      calc(random(100) * 1% - 50%)
    );
  }
}

.heading .word:nth-child(1) {
  animation-delay: 0.1s;
}
.heading .word:nth-child(2) {
  animation-delay: 0.2s;
}
.heading .word:nth-child(3) {
  animation-delay: 0.3s;
}
.heading .word:nth-child(4) {
  animation-delay: 0.4s;
}
.heading .word:nth-child(5) {
  animation-delay: 0.5s;
}
.heading .word:nth-child(6) {
  animation-delay: 0.6s;
}
.heading .word:nth-child(7) {
  animation-delay: 0.7s;
}
.heading .word:nth-child(8) {
  animation-delay: 0.8s;
}
.heading .word:nth-child(9) {
  animation-delay: 0.9s;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .products-button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
}
