.our-work {
  position: relative;
  padding: 6rem 0;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(45deg, #011f4b, #03396c, #005b96);
}

/* .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  opacity: 0.95;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(5px);
  transform: scale(1.1);
} */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7),
    0 0 20px rgba(255, 255, 255, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.title-word {
  animation: color-animation 4s linear infinite;
  padding: 0 10px;
}

.title-word:nth-child(1) {
  animation-delay: 0s;
}
.title-word:nth-child(2) {
  animation-delay: 0.66s;
}
.title-word:nth-child(3) {
  animation-delay: 1.33s;
}

@keyframes color-animation {
  0% {
    color: #ffffff;
  }
  32% {
    color: #ffffff;
  }
  66% {
    color: #ffffff;
  }
  100% {
    color: #ffffff;
  }
}

.section-subtitle {
  font-size: 1.5rem;
  color: #b3e0ff;
  text-align: center;
  margin-bottom: 3rem;
  animation: fadeInUp 0.6s ease forwards 0.2s;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.pool-card {
  width: 100%;
  max-width: 400px;
  height: 500px;
  perspective: 1000px;
  margin-bottom: 2rem;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.pool-card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-front {
  background-color: rgba(240, 240, 240, 0.1);
  backdrop-filter: blur(5px);
}

.card-back {
  background: linear-gradient(
    135deg,
    rgba(3, 57, 108, 0.8),
    rgba(1, 31, 75, 0.8)
  );
  transform: rotateY(180deg);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(5px);
}

.card-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.card-title {
  color: white;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.card-back .card-title {
  color: #05d9e8;
  text-shadow: none;
}

.card-underline {
  width: 50px;
  height: 3px;
  background-color: #05d9e8;
}

.card-description {
  color: #b3e0ff;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.6;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.feature-item {
  color: #ffffff;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.feature-item::before {
  content: "•";
  color: #ff7d00;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.learn-more-btn {
  background-color: #ff7d00;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: flex-start;
  margin-top: -1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
  bottom: 1rem;
  z-index: 10;
}

.learn-more-btn:hover {
  background-color: #e67100;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .pool-card {
    height: auto;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .card-inner {
    height: auto;
    transform-style: flat;
    transform: none !important;
  }

  .card-front,
  .card-back {
    position: static;
    transform: none !important;
    margin-bottom: 1rem;
  }

  .card-back {
    background: linear-gradient(
      135deg,
      rgba(3, 57, 108, 0.95),
      rgba(1, 31, 75, 0.95)
    );
  }

  .card-image {
    height: 200px;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .feature-item {
    font-size: 0.8rem;
  }

  .learn-more-btn {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 10rem;
  }
}

/* Small tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .section-title {
    font-size: 2.2rem;
  }

  .section-subtitle {
    font-size: 1.1rem;
  }

  .pool-card {
    height: auto;
    max-width: calc(50% - 1rem);
    margin-bottom: 2rem;
  }

  .card-inner {
    height: 100%;
  }

  .card-front,
  .card-back {
    position: absolute;
  }

  .card-image {
    height: 250px;
  }

  .learn-more-btn {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    position: relative;
    bottom: 12rem;
  }
}

/* Large tablets and small desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .section-title {
    font-size: 2.8rem;
  }

  .section-subtitle {
    font-size: 1.3rem;
  }

  .pool-card {
    max-width: calc(50% - 1rem);
  }

  .learn-more-btn {
    font-size: 0.85rem;
    padding: 0.6rem 1.2rem;
    position: relative;
    bottom: 0.75rem;
  }
}

/* Larger desktops */
@media (min-width: 1025px) {
  .pool-card {
    max-width: 400px;
  }

  .learn-more-btn {
    position: relative;
    bottom: 1rem;
  }
}
