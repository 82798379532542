.other-services {
  min-height: 100vh;
  background: linear-gradient(135deg, #0c4a6e, #0369a1, #0284c7);
  color: white;
  padding: 5rem 1rem;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.other-services.visible {
  opacity: 1;
  transform: translateY(0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.subtitle {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: fadeInUp 1s ease-out;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.service-card {
  width: 100%;
  max-width: 280px;
  height: 400px;
  perspective: 1000px;
  animation: fadeInLeft 1s ease-out;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.service-card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-front {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.card-back {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transform: rotateY(180deg);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.card-title {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 0.5rem;
}

.card-underline {
  width: 50px;
  height: 3px;
  background-color: #0284c7;
}

.card-content {
  flex-grow: 1;
}

.card-description {
  color: white;
  margin-bottom: 1rem;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.feature-item {
  color: white;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.feature-item::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #0284c7;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.learn-more-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #0284c7;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.learn-more-btn:hover {
  background-color: #0369a1;
  transform: translateY(-2px);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .service-card {
    height: auto;
  }

  .card-inner {
    transform-style: flat;
  }

  .service-card:hover .card-inner {
    transform: none;
  }

  .card-front,
  .card-back {
    position: relative;
    backface-visibility: visible;
  }

  .card-front {
    height: 200px;
  }

  .card-back {
    transform: none;
  }

  .card-image {
    height: 200px;
  }
}
